import React, { useContext, useEffect, useState } from "react";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import { graphql, useLazyQuery, useQuery } from "react-apollo";
import { DiscreteColorLegend, RadialChart, XAxis } from "react-vis";
import { format } from "date-fns";
import TimeAgo from "react-timeago";
import _ from "lodash";
import { read, utils, writeFile } from "xlsx";
import { Row, Col, Badge, Button, Dropdown, Form, Icon, Menu, Spin, Typography, Tooltip, Alert, Table, Popover, Tabs, Progress } from "antd";
import { ClientInfoBar, Titlebar, TotalClientMap } from "../../components";
import DashboardWrapper from "./index.style";
import { Link } from "react-router-dom";
import CalendarImg from "../../assets/img/icon-calendar.svg";
import FilterDelImg from "../../assets/img/clear-icon.png";
import Info from "../../assets/img/info-blue.svg";
import filterSortImg from "../../assets/img/filter-icon.svg";
import expandImg from "../../assets/img/icon-expand.svg";
import arrowImg from "../../assets/img/arrow-icon.svg";
import { Modal } from "react-bootstrap";
import CalendarModal from "./components/calendarModal";
import AssessmentModal from "./components/assessmentModal";
import moment from "moment";
import TitlebarWrapper from "../../components/titlebar/index.style";
import { AccountContext } from "../../context/AccountContext";
import TitleBar from "../../components/titlebar/titlebar";
import FilterDashboard from "../../components/filterDashboard";
import NormalizeData from "../../components/clientInfoBar/clientInfoFormula";
import { returnVehicle } from "../../utils/returnVehicle";

const { TabPane } = Tabs;

const popoverContent = (
  <div>
    <h6 className="popupTitle">Export Client List</h6>
    <p className="popup-blue">Click the download icon to access a CSV file of clients listed in this table.</p>
  </div>
);

const Dashboard = (props) => {
  const {
    currentPractice,
    me,
    // data: { loading: dataLoading, error: dataError, getClientForMap, refetch },
    // getAdvisorsForDashboard: { loading: advisorsLoading, error: advisorsError, getAdvisorsForDashboard },
  } = props;

  const [loading, setLoading] = useState(false);
  const [isCalendarModal, setCalendarModal] = useState(false);
  const [isAssessmentModal, setAssessmentModal] = useState(false);
  const [isFilterModal, setFilterModal] = useState(false);
  const [filterDate, setFilterDate] = useState({ startDate: null, endDate: null });
  const [filterType, setFilterType] = useState(null);
  const [decisionChartData, setDecisionChartData] = useState([]);
  const [riskChartData, setRiskChartData] = useState([]);
  const [assessmentViewType, setAssessmentViewType] = useState("completed");
  const [assessmentOrder, setAssessmentOrder] = useState(null);
  const [assessmentSearch, setAssessmentSearch] = useState(null);
  const [mapType, setMapType] = useState([]);
  const accountContext = useContext(AccountContext);
  const { isAssessmentChange, setAssessmentChange } = accountContext;

  useEffect(() => {
    setCalendarModal(false);
    clientForMapHandler({
      variables: {
        filters: {
          practiceId: me.practiceid ? me.practiceid : null,
          accountType: me.accountType,
          roleCode: me.rolecode,
          dateRange: filterDate,
          portalId: me.portalid.length > 0 ? me.portalid[0] : 0,
          type: mapType.join(","),
          ...filterType,
        },
      },
    });
    clientForMapHeaderHandler({
      variables: {
        filters: {
          practiceId: me.practiceid ? me.practiceid : null,
          accountType: me.accountType,
          roleCode: me.rolecode,
          dateRange: filterDate,
          portalId: me.portalid.length > 0 ? me.portalid[0] : 0,
          ...filterType,
        },
      },
    });
    clientTableHandler({
      variables: {
        filters: {
          practiceId: me.practiceid,
          accountType: me.accountType,
          roleCode: me.rolecode,
          portalId: me.portalid.length > 0 ? me.portalid[0] : 0,
          dateRange: filterDate,
          type: mapType.join(","),
          ...filterType,
        },
      },
    });
  }, [filterDate]);

  useEffect(() => {
    clientForMapHandler({
      variables: {
        filters: {
          practiceId: me.practiceid ? me.practiceid : null,
          accountType: me.accountType,
          roleCode: me.rolecode,
          portalId: me.portalid.length > 0 ? me.portalid[0] : 0,
          dateRange: filterDate,
          type: mapType.join(","),
          ...filterType,
        },
      },
    });
    clientForMapHeaderHandler({
      variables: {
        filters: {
          practiceId: me.practiceid ? me.practiceid : null,
          accountType: me.accountType,
          roleCode: me.rolecode,
          dateRange: filterDate,
          portalId: me.portalid.length > 0 ? me.portalid[0] : 0,
          ...filterType,
        },
      },
    });
    clientTableHandler({
      variables: {
        filters: {
          practiceId: me.practiceid,
          accountType: me.accountType,
          roleCode: me.rolecode,
          dateRange: filterDate,
          portalId: me.portalid.length > 0 ? me.portalid[0] : 0,
          type: mapType.join(","),
          ...filterType,
        },
      },
    });
  }, [filterType]);

  useEffect(() => {
    if (!me) return;
    clientForMapHandler();
    clientTableHandler();
    decisionChartHandler();
    clientForMapHeaderHandler();
    advisorListHandler();
    practiceListHandler();
    window.scrollTo(0, 0);
  }, [me]);

  useEffect(() => {
    if (assessmentViewType == "completed") recenltyCompleteHandler();
    if (assessmentViewType && assessmentViewType !== "completed") sentViewHandler();
  }, [assessmentViewType, assessmentOrder, assessmentSearch]);

  useEffect(() => {
    if (!isAssessmentChange) return;
    if (assessmentViewType != "sent") return;
    sentViewHandler();
    setAssessmentChange(null);
  }, [isAssessmentChange, assessmentViewType]);

  //  CLIENT CHART WITH DATE FILTER
  const [clientForMapHandler, { loading: dataLoading, data: getClientForMapData, error: dataError }] = useLazyQuery(
    gql`
      query($filters: JSON) {
        getClientForMap(filters: $filters) {
          clients {
            id
            dateCollected
            firstName
            insights
            lastName
            practice
            practiceName
          }
          clientshead
          pagination {
            totalCount
          }
        }
      }
    `,
    {
      variables: {
        filters: {
          practiceId: me.practiceid ? me.practiceid : null,
          accountType: me.accountType,
          roleCode: me.rolecode,
          portalId: me.portalid.length > 0 ? me.portalid[0] : 0,
        },
      },
      fetchPolicy: "network-only",
      onCompleted: (data) => {},
    }
  );

  const [practiceListHandler, { loading: practiceLoading, data: practiceData, error: practiceError }] = useLazyQuery(
    gql`
      query($filters: JSON) {
        getPracticesDashboard(filters: $filters) {
          result
        }
      }
    `,
    {
      fetchPolicy: "network-only",
      variables: {
        filters: {
          practiceId: me.practiceid ? me.practiceid : null,
          accountType: me.accountType,
          roleCode: me.rolecode,
          portalId: me.portalid.length > 0 ? me.portalid[0] : 0,
        },
      },
    }
  );

  const [advisorListHandler, { loading: advisorLoading, data: advisorData, error: advisorError }] = useLazyQuery(
    gql`
      query($filters: JSON) {
        getAdvisorDashboard(filters: $filters) {
          result
        }
      }
    `,
    {
      fetchPolicy: "network-only",
      variables: {
        filters: {
          practiceId: me.practiceid ? me.practiceid : null,
          accountType: me.accountType,
          roleCode: me.rolecode,
          portalId: me.portalid.length > 0 ? me.portalid[0] : 0,
        },
      },
    }
  );

  const [clientForMapHeaderHandler, { loading: dataHeaderLoading, data: getClientForMapHeaderData, error: dataHeaderError }] = useLazyQuery(
    gql`
      query($filters: JSON) {
        getClientForMap(filters: $filters) {
          clients {
            id
            dateCollected
            firstName
            insights
            lastName
            practice
            practiceName
          }
          clientshead
          pagination {
            totalCount
          }
        }
      }
    `,
    {
      variables: {
        filters: {
          practiceId: me.practiceid ? me.practiceid : null,
          accountType: me.accountType,
          roleCode: me.rolecode,
          portalId: me.portalid.length > 0 ? me.portalid[0] : 0,
        },
      },
      fetchPolicy: "network-only",
      onCompleted: (data) => {},
    }
  );

  useEffect(() => {
    if (!getClientForMapHeaderData) return;
    if (riskChartData.length > 0) return;
    let clientMapDataHeads = _.get(getClientForMapHeaderData, "getClientForMap.clients", []);

    if (clientMapDataHeads.length > 0) {
      const result = NormalizeData(clientMapDataHeads);
      let riskArr = [];
      let color = "";
      for (let res of result) {
        if (res.type == "Protector") color = "#D230AF";
        else if (res.type == "Observer") color = "#30ABD2";
        else if (res.type == "Liberator") color = "#9B4BEB";
        else color = "#D2A530";

        riskArr.push({ angle: res.angle != 0 ? Number(res.angle) : 0, color: color, name: res.type });
      }
      setRiskChartData(riskArr);
    }
  }, [getClientForMapHeaderData]);

  //  MEMBER TABLE LIST
  const [clientTableHandler, { loading: clientTableLoading, data: clientTableData, refetch: clientRefetch }] = useLazyQuery(
    gql`
      query($filters: JSON) {
        getClientTableForDashboard(filters: $filters) {
          clients
          pagination {
            totalCount
          }
        }
      }
    `,
    {
      variables: {
        filters: {
          practiceId: me.practiceid,
          accountType: me.accountType,
          roleCode: me.rolecode,
          portalId: me.portalid.length > 0 ? me.portalid[0] : 0,
        },
      },
      fetchPolicy: "network-only",
      onCompleted: (data) => {},
    }
  );

  const [clientTableExportHandler, { loading: clientTableExportLoading, data: clientTableExportData, refetch: clientExportRefetch }] = useLazyQuery(
    gql`
      query($filters: JSON) {
        getClientTableForDashboard(filters: $filters) {
          clients
          pagination {
            totalCount
          }
        }
      }
    `,
    {
      variables: {
        filters: {
          practiceId: me.practiceid,
          accountType: me.accountType,
          roleCode: me.rolecode,
          portalId: me.portalid.length > 0 ? me.portalid[0] : 0,
          dataExport: true,
        },
      },
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        const exportData = data.getClientTableForDashboard.clients;
        if (exportData.length > 0) {
          const headings = [["Client Email Address", "Client First Name", "Client Last Name", "Last Assessment Date", "Practice Name", "Advisor Name", "Financial Personality", "Score", "Decision Making Style", "Retirement Vehicle"]];
          const wb = utils.book_new();
          const ws = utils.json_to_sheet([]);
          utils.sheet_add_aoa(ws, headings);
          utils.sheet_add_json(ws, exportData, { origin: "A2", skipHeader: true });
          utils.book_append_sheet(wb, ws, "Report");
          writeFile(wb, "UnitifiDashboard.csv");
        }
      },
    }
  );

  // RECENT COMPLETE LIST
  const [recenltyCompleteHandler, { loading: recentalyLoading, data: recentlyCompleteData, refetch: recentComplateRefetch }] = useLazyQuery(
    gql`
      query($filters: JSON) {
        getRecentlyCompletedAssessment(filters: $filters) {
          clients
          pagination {
            totalCount
          }
        }
      }
    `,
    {
      variables: {
        filters: {
          practiceId: me.practiceid,
          order: assessmentOrder,
          search: assessmentSearch,
          accountType: me.accountType,
          roleCode: me.rolecode,
          portalId: me.portalid.length > 0 ? me.portalid[0] : 0,
        },
      },
      fetchPolicy: "network-only",
      onCompleted: (data) => {},
    }
  );

  // DECISION MAKING CHART
  const [decisionChartHandler, { loading: decisionLoading, data: decisionData }] = useLazyQuery(
    gql`
      query($filters: JSON) {
        getDecisionMakingChart(filters: $filters) {
          clients
          pagination {
            totalCount
          }
        }
      }
    `,
    {
      variables: {
        filters: {
          practiceId: me.practiceid ? me.practiceid : null,
          portalId: me.portalid.length > 0 ? me.portalid[0] : 0,
          accountType: me.accountType,
          roleCode: me.rolecode,
          advisorId: "",
        },
      },
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (data.getDecisionMakingChart && data.getDecisionMakingChart.clients) {
          let result = data.getDecisionMakingChart.clients;
          let decisionArr = [];
          let riskArr = [];
          Object.entries(result).map(([key, value]) => {
            if (key == "logical" || key == "passionate" || key == "balanced")
              decisionArr.push({ angle: value != "NaN" && value != "0" && value != "0.00" ? Number(value) : 0, color: key == "logical" ? "#004F86" : key == "passionate" ? "#589BCB" : "#006FBA", name: key });
            else {
              let color = "#589BCB";
              if (key == "moderatlyConservative") color = "#075891";
              if (key == "moderate") color = "#006FBA";
              if (key == "moderatlyAggressive") color = "#075891";
              if (key == "aggressive") color = "#054471";

              riskArr.push({ angle: value != "NaN" && value != "0" && value != "0.00" ? Number(value) : 0, color, name: key });
            }
          });

          setDecisionChartData(decisionArr);
          // setRiskChartData(riskArr);
        }
      },
    }
  );

  const [sentViewHandler, { loading: sentViewLoading, data: sentViewCompleteData, refetch: sentViewRefetch }] = useLazyQuery(
    gql`
      query($filters: JSON) {
        getSentViewedAssessment(filters: $filters) {
          clients
          pagination {
            totalCount
          }
        }
      }
    `,
    {
      variables: {
        filters: {
          practiceId: me.practiceid ? me.practiceid : null,
          advisorId: "",
          dateRange: filterDate,
          type: assessmentViewType,
          order: assessmentOrder,
          search: assessmentSearch,
          portalId: me.portalid.length > 0 ? me.portalid[0] : 0,
          accountType: me.accountType,
          roleCode: me.rolecode,
        },
      },
      fetchPolicy: "network-only",
      onCompleted: (data) => {},
    }
  );

  const tabChangeHandler = (e) => {
    setAssessmentViewType(e);
    // if (e == "completed") recenltyCompleteHandler();
    // else {
    //   setAssessmentViewType(e);
    // }
  };

  const handleClientTableChange = (event) => {};

  const setAssessmentOrderHandler = (data) => {
    setAssessmentOrder(data);
  };
  const changeCharthandler = (event) => {
    let mapTypeClone = [...mapType];

    const mapIndex = mapType.findIndex((data) => data == event);

    if (mapIndex > -1) mapTypeClone = mapTypeClone.filter((data) => data != event);
    else mapTypeClone = [...mapTypeClone, event];

    setMapType(mapTypeClone);

    clientForMapHandler({
      variables: {
        filters: {
          practiceId: me.practiceid ? me.practiceid : null,
          accountType: me.accountType,
          roleCode: me.rolecode,
          dateRange: filterDate,
          portalId: me.portalid.length > 0 ? me.portalid[0] : 0,
          type: mapTypeClone.join(","),
          ...filterType,
        },
      },
    });
    clientTableHandler({
      variables: {
        filters: {
          practiceId: me.practiceid,
          accountType: me.accountType,
          roleCode: me.rolecode,
          dateRange: filterDate,
          portalId: me.portalid.length > 0 ? me.portalid[0] : 0,
          type: mapTypeClone.join(","),
          ...filterType,
        },
      },
    });
  };
  const clearFilterHandler = () => {
    setFilterType(null);
    setFilterDate({ startDate: null, endDate: null });
  };

  if (decisionLoading || recentalyLoading) {
    return (
      <div>
        {/* <Titlebar currentPractice={currentPractice} displayText="Dashboard" /> */}
        <div className="loading-wrapper">
          <Spin indicator={<Icon spin style={{ fontSize: 24 }} type="loading" />} />
        </div>
      </div>
    );
  }

  if (dataError) {
    return (
      <div>
        <Titlebar currentPractice={currentPractice} displayText="Dashboard" />
        <div className="loading-wrapper">
          <p>Whoopsie - dashboard didn&apos;t load.</p>
        </div>
      </div>
    );
  }

  // const { advisors } = getAdvisorsForDashboard;
  // const {
  //   clients,
  //   pagination: { totalCount = 0 },
  // } = getClientForMap;

  let totalCount = _.get(getClientForMapData, "getClientForMap.pagination.totalCount", 0);
  let clientTablesData = _.get(clientTableData, "getClientTableForDashboard.clients", []);

  const account = _.get(accountContext, "account", null);
  const advisors = _.get(advisorData, "getAdvisorDashboard.result", []);
  const practices = _.get(practiceData, "getPracticesDashboard.result", []);
  const decisionTotal = _.get(decisionData, "getDecisionMakingChart.pagination.totalCount", []);

  if (totalCount === 0 && decisionTotal == 0 && !decisionLoading && !clientTableLoading) {
    return (
      <div style={{ padding: "24px" }}>
        <TitleBar title="Dashboard" subTitle={account && (account.accountType == 1 ? account.portalname : `${account.practicename} | ${account.portalname}`)} />

        {/* <Titlebar currentPractice={currentPractice} displayText="Dashboard" /> */}
        <DashboardWrapper style={{ padding: "24px 0" }}>
          <div className="main-wrapper">
            <div className="dashboard-chart-wrapper" style={loading ? { opacity: ".5" } : null}>
              {/* <div style={{ padding: "0 24px" }}> */}
              <div className="dashboard-content-wrapper">
                <p className="assessment-info">No clients have submitted an assessment.</p>
                <p style={{ marginBottom: "24px" }}>Let&#39;s get started by adding a client and sending an assessment.</p>
                <Link to={`${currentPractice}/clients/add`}>
                  <Button type="primary">Add A Client</Button>
                </Link>
              </div>
              {/* </div> */}
            </div>
          </div>
        </DashboardWrapper>
      </div>
    );
  }

  const clientColumns = [
    {
      // dataIndex: "name",
      sorter: false,
      title: "NAME",

      render: (text, row) => {
        return (
          <>
            <Link to={`/${currentPractice}/clients/view/${text.id}`}>{text.name}</Link>
          </>
        );
      },
    },
    {
      dataIndex: "personality",
      title: "PERSONALITY",
      sorter: false,
    },
    {
      dataIndex: "score",
      render: (text) => <div>{text}</div>,
      title: "BEHAVIOR DEFINED",
      sorter: false,
    },
    {
      dataIndex: "decision_making_style",
      title: "DECISION MAKING STYLE",
      sorter: false,
    },
    {
      dataIndex: "retirement_vehicle",
      render: (vehicle) => returnVehicle[vehicle],
      title: "RETIREMENT VEHICLE",
      sorter: false,
    },
    {
      dataIndex: null,
      sorter: false,
      with: 2,
      className: "cursor-pointer",
      title: clientTableExportLoading ? (
        <Spin indicator={<Icon spin style={{ fontSize: 15 }} type="loading" />} />
      ) : (
        <>
          <Popover overlayClassName="clientOverview" trigger="hover" content={popoverContent}>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="17" viewBox="0 0 14 17" fill="none">
              <path d="M0.583336 16.3333H13.4167V14.5H0.583336V16.3333ZM13.4167 6.25H9.75V0.75H4.25V6.25H0.583336L7 12.6667L13.4167 6.25Z" fill="#181818" />
            </svg>
          </Popover>
          <Popover overlayClassName="clientOverview" trigger="hover" content={popoverContent}>
            <i className="ms-1">
              <img src={Info} className="mb-2" />
            </i>
          </Popover>
        </>
      ),
      onHeaderCell: (record) => {
        return {
          onClick: (ev) => {
            clientTableExportHandler({
              variables: {
                filters: {
                  practiceId: me.practiceid,
                  accountType: me.accountType,
                  roleCode: me.rolecode,
                  portalId: me.portalid.length > 0 ? me.portalid[0] : 0,
                  dataExport: true,
                  dateRange: filterDate,
                  type: mapType.join(","),
                  ...filterType,
                },
              },
            });
          },
        };
      },
    },
  ];

  let recentlyCompletedAssessment = _.get(recentlyCompleteData, "getRecentlyCompletedAssessment.clients", []);
  let sentAssessment = _.get(sentViewCompleteData, "getSentViewedAssessment.clients", []);
  let clientMapData = _.get(getClientForMapData, "getClientForMap.clients", []);
  let clientMapDataHead = _.get(getClientForMapHeaderData, "getClientForMap.clients", []);

  return (
    <>
      <Row gutter={24} className="m-2">
        <Col lg={14} xl={15} xxl={16}>
          <DashboardWrapper>
            <div className="dashboard-wrapper">
              <div className="main-wrapper p-0">
                <div className="dashboard-chart-wrapper" style={loading ? { opacity: ".5" } : null}>
                  <div className="d-flex align-items-center justify-content-between">
                    {/* <Titlebar currentPractice={currentPractice} displayText="Clients" /> */}
                    <TitleBar title="Clients" subTitle={account && (account.accountType == 1 ? account.portalname : `${account.practicename} | ${account.portalname}`)} />
                    {/* <TitlebarWrapper>
                      <div className="title-bar-wrapper">
                        <div className="title-wrapper">
                          <div className="title-text">Clients</div>
                          <div className="sub-title-text">{me && me.accountType == 1 ? me.portalname : me.practicename}</div>
                        </div>
                      </div>
                    </TitlebarWrapper> */}

                    {/* <div className="dashboard-chart-title">
                      Clients
                      <p>{currentPractice}</p>
                    </div> */}
                    {/* <button onClick={() => setCalendarModal(true)} className="m-3">Calendar</button> */}

                    <div className="d-flex flex-column align-items-end">
                      <span class="filterTitle">Filters</span>
                      <div className="d-flex align-items-center justify-content-end">
                        <span class="me-2 mt-1">
                          {filterDate.startDate && moment(filterDate.startDate).format("dddd, DD MMMM YYYY")}
                          {!filterDate.startDate ? `` : " - "}
                          {filterDate.startDate && moment(filterDate.endDate).format("dddd, DD MMMM YYYY")}
                        </span>
                        <a href="#" onClick={() => setCalendarModal(true)}>
                          <img src={CalendarImg} className="me-2" />
                        </a>
                        <a href="#" className="me-2" onClick={() => setFilterModal(true)}>
                          <img src={filterSortImg} />
                        </a>
                      </div>

                      {(filterType || filterDate.startDate) && (
                        <div class="me-2" onClick={() => clearFilterHandler()}>
                          <span className="filteText">
                            <a href="#">Clear Filters
                              <img src={FilterDelImg} />
                            </a>
                          </span>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="topbar-wrapper">{/* <div className="top-bar">
                      <div className="fw-bold">Conservative</div>
                      <div className="fw-bold">Aggressive</div>
                    </div> */}</div>
                  <ClientInfoBar data={clientMapDataHead} changeCharthandler={changeCharthandler} loading={loading} mapType={mapType} />
                  <TotalClientMap data={clientMapData} dataLoading={dataLoading} />

                  <div className="graph-bottom-bar gradientBg">
                    {/* <span>Conservative</span>
                    <span>Moderately Conservative</span>
                    <span>Moderate</span>
                    <span>Moderately Aggressive</span>
                    <span>Aggressive</span> */}
                    <span>Personality Spectrum</span>
                  </div>
                </div>
              </div>
            </div>
            <Modal show={isCalendarModal} onHide={() => setCalendarModal(false)} centered className="unitifi-modal-wrapper calendar-modal" size="lg">
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-sm">Filter By Date Range</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <CalendarModal setCalendarModal={setCalendarModal} setFilterDate={setFilterDate} filterDate={filterDate} />
              </Modal.Body>
            </Modal>
            <Modal show={isFilterModal} onHide={() => setFilterModal(false)} centered className="unitifi-modal-wrapper filterData-modal" size="lg">
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-sm">Filter By</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FilterDashboard isFilterModal={isFilterModal} setFilterModal={setFilterModal} advisors={advisors} practices={practices} setFilterType={setFilterType} filterType={filterType} me={me} />
              </Modal.Body>
            </Modal>
          </DashboardWrapper>
          <DashboardWrapper>
            <div className="dashboard-wrapper tableData mt-3">
              <div className="main-wrapper p-0 d-flex">
                <div className="client-table-data-wrapper">
                  <Table columns={clientColumns} loading={loading} onChange={handleClientTableChange} dataSource={clientTablesData} rowKey={(record) => record.id} pagination={false} scroll={{ y: 920 }} />
                </div>
              </div>
            </div>
          </DashboardWrapper>
        </Col>

        <Col lg={10} xl={9} xxl={8} className="px-0">
          <Row gutter={10}>
            <Col xxl={22}>
              <DashboardWrapper>
                <div className="dashboard-wrapper chart-box">
                  <div className="main-wrapper p-0">
                    <div className="p-3">
                      <h5>PERSONALITY SPECTRUM</h5>
                      <Row className="d-flex align-items-center justify-content-start">
                        <Col xxl={11}>
                          <div class="d-flex align-items-start flex-column">
                            <span className="square-box box1">Protector</span>
                            <span className="square-box box2">Observer</span>
                            <span className="square-box box3">Liberator</span>
                            <span className="square-box box4">Energizer</span>
                          </div>
                        </Col>
                        <Col xxl={11} className="ms-auto">
                          <RadialChart className="dashobard-chart" getLabel={(d) => d.label} data={riskChartData} labelsRadiusMultiplier={1.6} labelsStyle={{ fontSize: 16, fill: "#222" }} width={194} height={194} colorType="literal" />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </DashboardWrapper>
            </Col>

            {/* <Col xs={24} sm={12} xxl={11}>
              <DashboardWrapper>
                <div className="dashboard-wrapper chart-box">
                  <div className="main-wrapper p-0">
                    <div className="p-3">
                      <h5>DECISION MAKING STYLE</h5>
                      <RadialChart className="mx-auto dashobard-chart" getLabel={(d) => d.label} data={decisionChartData} labelsRadiusMultiplier={1.6} labelsStyle={{ fontSize: 16, fill: "#222" }} width={144} height={144} colorType="literal" />
                      <div class="d-flex align-items-center justify-content-evenly">
                        <span className="square-box">Logical</span>
                        <span className="square-box box1">Passionate</span>
                        <span className="square-box box2">Balanced</span>
                      </div>
                    </div>
                  </div>
                </div>
              </DashboardWrapper>
            </Col> */}
          </Row>

          <Row>
            <Col xxl={22}>
              <DashboardWrapper>
                <div className="dashboard-wrapper chart-box h-100">
                  <div className="main-wrapper p-0">
                    <div className="p-3">
                      <h5 className="mb-3">DECISION MAKING STYLE</h5>
                      <div className="mb-3">
                        <div className="d-flex align-items-center justify-content-between">
                          <span className="unitify-cmn-title">Logical</span>
                          <span className="unitify-cmn-title percentage">{decisionChartData.filter((data) => data.name == "logical").map((data) => data.angle)}%</span>
                        </div>
                        <Progress percent={decisionChartData.filter((data) => data.name == "logical").map((data) => data.angle)} status="active" showInfo={false} strokeColor="#004F86" />
                      </div>
                      <div className="mb-3">
                        <div className="d-flex align-items-center justify-content-between">
                          <span className="unitify-cmn-title">Passionate</span>
                          <span className="unitify-cmn-title percentage">{decisionChartData.filter((data) => data.name == "passionate").map((data) => data.angle)}%</span>
                        </div>
                        <Progress percent={decisionChartData.filter((data) => data.name == "passionate").map((data) => data.angle)} status="active" showInfo={false} strokeColor="#006FBA" />
                      </div>
                      <div className="mb-3">
                        <div className="d-flex align-items-center justify-content-between">
                          <span className="unitify-cmn-title">Balanced</span>
                          <span className="unitify-cmn-title percentage">{decisionChartData.filter((data) => data.name == "balanced").map((data) => data.angle)}%</span>
                        </div>
                        <Progress percent={decisionChartData.filter((data) => data.name == "balanced").map((data) => data.angle)} status="active" showInfo={false} strokeColor="#589BCB" />
                      </div>
                    </div>
                  </div>
                </div>
              </DashboardWrapper>
            </Col>
          </Row>
          <Row>
            <Col xxl={22}>
              <DashboardWrapper>
                <div className="modal-status-tab-wrapper">
                  <h6>Assessment Activity</h6>
                  <a className="expand-icon" onClick={() => setAssessmentModal(true)}>
                    <img src={expandImg} />
                  </a>
                  <Tabs defaultActiveKey="completed" onChange={tabChangeHandler} type="card">
                    <TabPane tab="RECENTLY COMPLETED" key="completed">
                      {recentlyCompletedAssessment.length > 0 &&
                        recentlyCompletedAssessment.map((recently) => (
                          <div key={recently.id} className="tab-data-content">
                            <p class="time-detail mb-0">
                              <TimeAgo date={recently.dateCompleted} />
                            </p>
                            <Link to={`/${currentPractice}/clients?client_id=${recently.id}`} className="text-decoration-none">
                              <p className="client-title">{recently.name}</p>
                            </Link>
                            <p className="score-data">{recently.score}</p>
                            <p className="client-title style-data">{recently.decision_making_style}</p>
                            <p className="client-title retirement-data">{returnVehicle[recently.retirement_vehicle]}</p>
                            <p class="view-data mb-0">
                              <Link to={`/${currentPractice}/clients/view/${recently.id}`}>View</Link>
                            </p>
                          </div>
                        ))}
                    </TabPane>
                    <TabPane tab="RECENTLY SENT" key="sent">
                      {sentAssessment.length > 0 &&
                        sentAssessment.map((sent) => (
                          <div key={sent.id} className="tab-data-content recent-data recently-tab-wrapper">
                            <div className="">
                              <p className={`score-data text-decortion-none mb-0 ${sent.name ? "client-name-data" : ""}`}>{sent.name}</p>
                              <p className="client-title text-lowercase style-data mb-0">{sent.email}</p>
                            </div>
                            <p class="time-detail recentData mb-0">
                              <TimeAgo date={sent.dateSent} />
                            </p>
                            {/*<p className="client-title">{sent.location !== ", " ? sent.location : ""}</p>*/}
                            {/*<p className="client-title retirement-data">{sent.phone}</p>*/}
                            {/*<p class="view-data mb-0">
                              <Link to={`/${currentPractice}/clients/view/${sent.id}`}>View</Link>
                            </p>*/}
                          </div>
                        ))}
                    </TabPane>
                    <TabPane tab="IN PROGRESS" key="viewed">
                      {sentAssessment.length > 0 &&
                        sentAssessment.map((sent) => (
                          <div key={sent.id} className="tab-data-content recent-data">
                            <div>
                              <Link to={`/${currentPractice}/clients?client_id=${sent.id}`} className="text-decoration-none">
                                {" "}
                                <p className="score-data text-decoration-none">{sent.name}</p>
                              </Link>
                              <p className="client-title text-lowercase style-data mb-0">{sent.email}</p>
                            </div>
                            <p class="time-detail mb-0">
                              <TimeAgo date={sent.viewbyClient} />
                            </p>
                            {/*<p className="client-title">{sent.location}</p>*/}

                            {/*<p className="client-title retirement-data">{sent.phone}</p>*/}
                            {/*<p class="view-data mb-0">
                              <Link to={`/${currentPractice}/clients/view/${sent.id}`}>View</Link>
                        </p>*/}
                          </div>
                        ))}
                    </TabPane>
                  </Tabs>
                </div>
                {/* <button onClick={() => setAssessmentModal(true)}>Open</button> */}
                <Modal show={isAssessmentModal} onHide={() => setAssessmentModal(false)} centered className="unitifi-modal-wrapper notify-modal vehicle-data" size="lg">
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body className="vehicle-data-main">
                    <AssessmentModal
                      me={me}
                      recentlyCompletedAssessment={recentlyCompletedAssessment}
                      {...props}
                      tabChangeHandler={tabChangeHandler}
                      sentAssessment={sentAssessment}
                      setAssessmentSearch={setAssessmentSearch}
                      setAssessmentOrderHandler={setAssessmentOrderHandler}
                      assessmentViewType={assessmentViewType}
                    />
                  </Modal.Body>
                </Modal>
              </DashboardWrapper>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* <Row gutter={20} className="m-2">
      <Col lg={16}>
     
      </Col>
    </Row> */}
    </>
  );
};

const getClientMapQuery = gql`
  query($filters: JSON) {
    getClientForMap(filters: $filters) {
      clients {
        id
        dateCollected
        firstName
        insights
        lastName
        practice
        practiceName
      }
      clientshead
      pagination {
        totalCount
      }
    }
  }
`;

const getAdvisorsForDashboardQuery = gql`
  {
    getAdvisorsForDashboard {
      advisors {
        id
        firstName
        lastName
        practiceId
        practiceName
      }
    }
  }
`;

export default compose()(Form.create()(Dashboard));
// graphql(getClientMapQuery, {
//   options: (props) => {
//     return {
//       fetchPolicy: "network-only",
//       variables: {
//         filters: {
//           practiceId: props.me.practiceid ? props.me.practiceid : null,
//           accountType: props.me.accountType,
//           roleCode: props.me.rolecode,
//         },
//       },
//     };
//   },
// })
// graphql(getAdvisorsForDashboardQuery, {
//   name: "getAdvisorsForDashboard",
//   options: {
//     fetchPolicy: "network-only",
//   },
// })
