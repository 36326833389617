import { Button, Col, Form, Modal, Row } from "antd";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import FilterDelImg from "../../assets/img/clear-icon.png";

import { FormWrapper } from "../form";
const FormItem = Form.Item;

const behaviorList = [
  // {
  //   label: "All",
  //   value: "",
  // },
  {
    label: "Conservative",
    value: "Conservative",
  },
  {
    label: "Moderately Conservative",
    value: "Moderately Conservative",
  },
  {
    label: "Moderate",
    value: "Moderate",
  },
  {
    label: "Moderately Aggressive",
    value: "Moderately Aggressive",
  },
  {
    label: "Aggressive",
    value: "Aggressive",
  },
];
const decisionMakingStyle = [
  // {
  //   label: "All",
  //   value: "",
  // },
  {
    label: "Logical",
    value: "Logical",
  },
  {
    label: "Passionate",
    value: "Passionate",
  },
  {
    label: "Balanced",
    value: "Balanced",
  },
];

const retirementVehicle = [
  // {
  //   label: "All",
  //   value: "",
  // },
  {
    label: "Work",
    value: "401(k) at work",
  },
  {
    label: "Individual",
    value: "IRA",
  },
  {
    label: "Both",
    value: "IRA and 401(k)",
  },
  {
    label: "None",
    value: "No Retirement Account Yet",
  },
  {
    label: "Other Retirement Vehicle",
    value: "Other Retirement Account",
  },
];

const FilterDashboard = ({ advisors, practices, form, setFilterType, setFilterModal, filterType, me }) => {
  const { getFieldProps, getFieldDecorator, getFieldValue, resetFields } = form;

  advisors = [...advisors.map((data) => ({ label: `${data.firstName} ${data.lastName}`, value: data.id }))];
  practices = [...practices.map((data) => ({ label: data.name, value: data.id }))];

  const [formValues, setFormValues] = useState({
    decisionType: [],
    retirementType: [],
    practiceType: [],
    advisorType: [],
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        let variable = {};
        if (values.advisorType.length > 0) variable.advisorType = values.advisorType.map((data) => data.value).join(",");
        // if (values.behaviorType.length > 0) variable.behaviorType = values.behaviorType.map((data) => data.value).join(",");
        if (values.decisionType.length > 0) variable.decisionType = values.decisionType.map((data) => data.value).join(",");
        if (values.practiceType.length > 0) variable.practiceType = values.practiceType.map((data) => data.value).join(",");
        if (values.retirementType.length > 0) variable.retirementType = values.retirementType.map((data) => data.value).join(",");

        if (Object.keys(variable).length > 0) setFilterType(variable);
        setFilterModal(false);
      }
    });
  };

  const resetFormHandler = (type = "cancel") => {
    resetFields();
    setFilterType(null);
    if (type == "cancel") setFilterModal(false);
  };

   // Set initial form values when component mounts or filterType changes
   useEffect(() => {
    const initialValues = {
      decisionType: (filterType && filterType.decisionType && decisionMakingStyle.filter((data) => filterType.decisionType.split(",").includes(data.value))) || [],
      retirementType: (filterType && filterType.retirementType && retirementVehicle.filter((data) => filterType.retirementType.split(",").includes(data.value))) || [],
      practiceType: (filterType && filterType.practiceType && practices.filter((data) => filterType.practiceType.split(",").includes(data.value.toString()))) || [],
      advisorType: (filterType && filterType.advisorType && advisors.filter((data) => filterType.advisorType.split(",").includes(data.value.toString()))) || [],
    };
    setFormValues(initialValues);
  }, [filterType]);

  const handleSelectChange = (key, value) => {
    //Update the specific select field in the state
    const updatedValues = { ...formValues, [key]: value };
    setFormValues(updatedValues);

    // Check if all values are empty
    const allEmpty = Object.values(updatedValues).every((val) => !val || val.length === 0);
    if (allEmpty) {
      resetFormHandler("clear")
    }
  };

  return (
    <FormWrapper>
      <Form layout="vertical" onSubmit={handleSubmit}>
        {/* <Row gutter={10}>
          <Col span={24}>
            <FormItem label="Risk Behavior">
              {getFieldDecorator("behaviorType", { initialValue: (filterType && filterType.behaviorType && behaviorList.filter((data) => filterType.behaviorType.split(",").includes(data.value))) || "" })(
                <Select isMulti options={behaviorList} placeholder="All" className="modal-filter-multi-select" classNamePrefix="unitifi-react-select" />
              )}
            </FormItem>
          </Col>
        </Row> */}

        <Row gutter={10}>
          <Col span={24}>
            <FormItem label="Decision Making Style">
              {getFieldDecorator("decisionType", { initialValue: (filterType && filterType.decisionType && decisionMakingStyle.filter((data) => filterType.decisionType.split(",").includes(data.value))) || "" })(
                <Select isMulti options={decisionMakingStyle} placeholder="All" className="modal-filter-multi-select" classNamePrefix="unitifi-react-select" onChange={(selected) => handleSelectChange("decisionType", selected)} />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <FormItem label="Retirement Vehicle">
              {getFieldDecorator("retirementType", { initialValue: (filterType && filterType.retirementType && retirementVehicle.filter((data) => filterType.retirementType.split(",").includes(data.value))) || "" })(
                <Select isMulti options={retirementVehicle} placeholder="All" className="modal-filter-multi-select" classNamePrefix="unitifi-react-select" onChange={(selected) => handleSelectChange("retirementType", selected)} />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <FormItem label="Practice">
              {getFieldDecorator("practiceType", { initialValue: (filterType && filterType.practiceType && practices.filter((data) => filterType.practiceType.split(",").includes(data.value.toString()))) || "" })(
                <Select isMulti options={practices} placeholder="All" className="modal-filter-multi-select" classNamePrefix="unitifi-react-select" isDisabled={me && (me.rolecode == "CMPYO" || me.rolecode == "CMPYM") ? false : true} onChange={(selected) => handleSelectChange("practiceType", selected)} />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <FormItem label="Advisor">
              {getFieldDecorator("advisorType", { initialValue: (filterType && filterType.advisorType && advisors.filter((data) => filterType.advisorType.split(",").includes(data.value.toString()))) || "" })(
                <Select isMulti options={advisors} placeholder="All" className="modal-filter-multi-select" classNamePrefix="unitifi-react-select" isDisabled={me && (me.rolecode == "ADVSO" || me.rolecode == "ADVSM") ? true : false} onChange={(selected) => handleSelectChange("advisorType", selected)} />
              )}
            </FormItem>
          </Col>
        </Row>
        {filterType && (
          <div class="mb-3 d-flex justify-content-end">
            <span className="filteText">
              <a href="#" onClick={() => resetFormHandler("clear")}>
                Clear Filters
                <img src={FilterDelImg} />
              </a>
            </span>
          </div>
        )}
        <div className="d-flex justify-content-end">
          <Button type="primary" htmlType="submit">
            Apply Filter
          </Button>
          <Button className="ms-3" onClick={() => resetFormHandler()}>
            Cancel
          </Button>
        </div>
      </Form>
    </FormWrapper>
  );
};

export default Form.create()(FilterDashboard);
