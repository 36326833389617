import { Button, Col, Form, Modal, Row, TreeSelect } from "antd";
import React from "react";
import FilterDelImg from "../../assets/img/clear-icon.png";

import { FormWrapper } from "../form";
const FormItem = Form.Item;
const { SHOW_PARENT } = TreeSelect;


const FilterClient = ({ formattedTreeAdvisors, form, clearFilter, setFilterModal, filterType, onAdvisorChange }) => {
  const { getFieldDecorator, setFieldsValue , resetFields } = form;


  let assignedToData = formattedTreeAdvisors;
  let sortedAssignedToData = formattedTreeAdvisors;

  const sortTreeData = (data, inputValue) => {
    if (!inputValue) return data;
  
    // Helper to determine if a title matches or partially matches the input
    const getMatchType = (title) => {
      if (title.toLowerCase() === inputValue.toLowerCase()) return 0; // Exact match
      if (title.toLowerCase().startsWith(inputValue.toLowerCase())) return 1; // Partial match
      if (title.toLowerCase().includes(inputValue.toLowerCase())) return 2; // Partial match
      return 3; // No match
    };
  
    // Recursively sort nodes
    const sortedData = data
      .map((node) => {
        // Sort children if they exist
        const children = node.children ? sortTreeData(node.children, inputValue) : undefined;
        return { ...node, children };
      })
      .sort((a, b) => getMatchType(a.title) - getMatchType(b.title)); // Sort by match type
  
    return sortedData;
  };

  const handleFilterTreeNode = (inputValue, node) => {
    const matchesNode = node.props.title.toLowerCase().includes(inputValue.toLowerCase());
    if (matchesNode) {
      // If parent node matches, skip searching in children
      return true;
    }
    if (node.props.isPracice == false) {
      const parent = assignedToData.flatMap(item => item.children || []).find(parent => parent.children.some(child => child.value == node.props.value));
      if (parent && parent.title.toLowerCase().includes(inputValue.toLowerCase())) {
        return true;
      }
    }
    return false
  };

  const handleSearch = (inputValue) => {
    if (!inputValue) {
      sortedAssignedToData = assignedToData;
        return;
    }
    const sortedData = sortTreeData(assignedToData, inputValue);
    sortedAssignedToData = sortedData;
  }

  const handleChange = (value) => {
    if (value.length == 0 && filterType.length > 0) {
      resetFormHandler("clear")
    }
  }

  const tProps = {
    treeData: sortedAssignedToData,
    filterTreeNode:(inputValue, node) => handleFilterTreeNode(inputValue, node),
    onSearch:handleSearch,
    onChange: handleChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    searchPlaceholder: "Please select",
    style: {
      width: "100%",
      height: "40px", 
      overflow: "hidden",
    },
    maxTagCount: 1,
    maxTagPlaceholder: (omittedValues) => `+ ${omittedValues.length} more`,
    treeDefaultExpandedKeys: ['0_0']
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        let variable = { advisorType : values.advisorType };
        setFilterModal(false);
        onAdvisorChange(variable, formattedTreeAdvisors);
      }
    });
  };

  const resetFormHandler = (type = "cancel") => {
    resetFields();
    form.resetFields();
    setFieldsValue({ 'advisorType': [] })
    if (filterType.length > 0) {
      clearFilter();
    }
    if (type == "cancel") setFilterModal(false);
  };


  return (
    <FormWrapper>
      <Form layout="vertical" onSubmit={handleSubmit}>
        <Row gutter={10}>
          <Col span={24}>
            <FormItem className={"dropdown-icon-wrapper selected-filter-modal-input"} label="Practice/Advisor">
              {getFieldDecorator("advisorType", { initialValue: filterType, rules: [
                  {
                    message: "Required.",
                    required: true,
                  },
                ] })(
                <TreeSelect
                  placeholder="Please select"
                  {...tProps}
                  showSearch
                  dropdownClassName="modal-filter-multi-select selected-assign-tasks"
                  classNamePrefix="unitifi-react-select"
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        {filterType.length > 0 && (
          <div className="mb-3 d-flex justify-content-end">
            <span className="filteText">
              <a href="#" onClick={() => resetFormHandler("clear")}>
                Clear Filters
                <img src={FilterDelImg} />
              </a>
            </span>
          </div>
        )}
        <div className="d-flex justify-content-end">
          <Button type="primary" htmlType="submit">
            Apply Filter
          </Button>
          <Button className="ms-3" onClick={() => resetFormHandler()}>
            Cancel
          </Button>
        </div>
      </Form>
    </FormWrapper>
  );
};

export default Form.create()(FilterClient);
