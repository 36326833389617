import styled from 'styled-components';
import { palette } from 'styled-theme';

const LayoutContentWrapper = styled.div`
  background: ${palette('base', 0)};
  border: 1px solid rgba(238,242,244,1);
  border-radius: 4px;
  margin: 24px 24px 0;
  padding: 24px;

  .clientlist-table-wrapper{
    .ant-table-wrapper{
      .ant-table{
        .ant-table-content{
          .ant-table-body{
            table{
              background:#fff;
              tbody{
                      tr{
                              td{
                                background:none;
                                font-weight:400;
                                line-height:17px;
                              }
                              &.active-row  {
                                background: #006FBA !important;
                                color:#fff !important;
                                td{
                                  a{
                                    &:first-child {
                                    color: #fff;
                                    }
                                  }
                                  &.action-column{
                                      background: #006FBA !important;
                                      color:#fff !important;
                                      span{
                                        .ant-btn{
                                          .anticon{
                                            svg{
                                              fill:#fff;
                                            }
                                          }
                                        }
                                      }
                                  }
                                  &.archiveColumn{
                                  .ant-btn-primary{
                                      background:#fff !important;
                                      border-color:#fff !important;
                                      color:#006fba !important;
                                  }
                                }    
                              }
                              }
                              &:hover{
                                  background: #e1f4fa !important;
                                  // color:#fff !important;
                                  transition: all 0.3s ease;
                              td{
                                a{
                                  &:first-child {
                                  // color: #fff;
                                  }
                                }
                                &.action-column{
                                      background: #e1f4fa !important;
                                      // color:#fff !important;
                                      span{
                                        .ant-btn{
                                          .anticon{
                                            svg{
                                              // fill:#fff;
                                            }
                                          }
                                        }
                                      }
                                }
                                &:nth-child(6){
                                  .ant-btn-primary{
                                    // background:#f5f5f5;
                                    // border-color:#f5f5f5;
                                    // color:rgba(0,0,0,0.6);
                                  }
                                }
                              }
                            }
                        }
                  }
            }
          }
        }
      }
      }
  }

  .practiceTblWrapper{
    .ant-table-wrapper{
      .ant-table-body{
        display:flex;
        table{
          &.ant-table-fixed{
            max-width:1199px;
            width:100%;
            flex:0 0 1199px;
            overflow:auto;
            thead{
              tr{
                th{
                  &.action-column{
                    text-align:left !important;
                    width:10px !important;
                    padding:14px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .advisorTblWrapper{
    .ant-table-wrapper{
      .ant-table-body{
        display:flex;
        table{
          &.ant-table-fixed{
            max-width:1200px;
            width:100%;
            flex:0 0 1200px;
            overflow:auto;
          }
        }
      }
    }
  }

  &.faq-layout{
    padding:50px;
  }
  @media only screen and (max-width: 767px) {
    margin: 24px 0;
  }
  &.margin-remove-div{
    margin: 0 0 30px;
    min-height: 330px;
  }
  .display-bar {
    color: rgba(0,0,0,.85);
    font-size: 12px;
    padding-bottom: 24px;
    &.with-search {
      padding-bottom: 12px;
    }
  }
  .headingUser{
    font-size:20px;
    font-weight:600;
    line-height:25px;
    color:#181818;
    margin-bottom:20px;
  }
  .displayData{
    font-size:16px;
    font-weight:400;
    line-height:20px;
    color:#181818;
    padding-left:15px;
    padding-bottom: 24px;
  }
  .search-bar-wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
    .search-bar-inner {
      width: calc(100% - 200px);
    }
    @media only screen and (max-width: 767px) {
      flex-wrap: wrap;
      .search-bar-inner {
        width: 100%;
      }
    }
  }
  .search-bar {
    max-width: 424px;
    .has-search {
      .ant-input-suffix .ant-input-search-button {
        right: 34px;
      }
    }
  }
  .show-wrapper {
    align-items: center;
    display: flex;
    margin-bottom: 24px;
    .show-label {
      margin-right: 8px;
    }
  }
  &.support-roles-note-section{
    padding: 15px;
    h5{
      font-size: 16px;
      font-weight: 700;
      color:#4E4E4E;
      margin-bottom: 12px;
      letter-spacing: -0.02em;
      &.support-text{
        font-weight: 400;
        span{
          font-weight: 700;
        }
      }
    }
    ul{
      li{
        font-size: 16px;
        line-height: 20.11px;
        letter-spacing: -0.02em;
        color:#4E4E4E;
        font-weight: 400;
        margin-bottom: 8px;
        span{
          font-weight: 700;
          display: inline-block;
        }
      }
    }
    h6{
      font-size: 14px;
      font-weight: 600;
      line-height: 17.6px;
      letter-spacing: -0.02em;
      color:#4E4E4E;
      margin-bottom: 0;
    }
  }
`;

export default LayoutContentWrapper;
