import styled from "styled-components";
import { palette } from "styled-theme";

const ContentWrapper = styled.div`
  min-height: 100vh;

  .button-bar-wrapper {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 24px 40px 0 0;
    width: 100%;
    @media only screen and (max-width: 767px) {
      padding-right: 24px;
    }

    &.has-dropdown {
      .ant-dropdown-trigger .anticon:not(.anticon-ellipsis),
      .ant-dropdown-link .anticon:not(.anticon-ellipsis) {
        font-size: 14px;
        transform: scale(1);
      }
      &.download {
        .ant-dropdown-trigger .anticon:not(.anticon-ellipsis),
        .ant-dropdown-link .anticon:not(.anticon-ellipsis) {
          font-size: 16px;
        }
      }
    }
    .ant-btn{
      &.btnCompare{
        background:#fff;
        border:1px solid #006fba;
        border-radius:4px;
        height:33px;
        color:#006fba;
        font-size:16px;
        font-weight:600;
        margin-right:10px;
      }
    }
  }

  .layout {
    background: /*rgba(249,250,252,1)*/ rgba(240,242,245,1);
    padding-left: 256px;
    transition: all .2s;
    &.collapsed {
      padding-left: 0;
      transition: all .2s;
    }
    /* @media only screen and (max-width: 767px) { */
    @media only screen and (max-width: 9000px) {
      padding-left: 0;
      &.collapsed {
        padding-left: 0;
      }
    }

    .ant-layout-content {
      .content-wrapper {
        margin-top: 90px;
        min-height: calc(100vh - 108px);
        /* padding: 24px; */
        padding: 0 0 24px 0;
        /* margin: 64px 40px 40px; */
        /* min-height: calc(100vh - 98px - 85px - 24px); */
         @media only screen and (max-width: 767px) {
          margin-top: 125px;
        }


    .unitifi-notification-banner-data{
      width:100%;
      border-radius:4px;
      background:#006fba;
      display:flex;
      align-items:flex-start;
      &.dashboard-notification-banner{
        max-width:1760px;
      }
      @media(max-width:991px){
        flex-direction:column;
      }
      .notification-icon-wrapper{
          background:#003f83;
          border-radius:4px 0 0 4px;
          display:flex;
          align-items:center;
          justify-content:center;
          max-width:48px;
          width:100%;
          padding:27px 15px;
          @media(max-width:1199px){
            padding:39px 15px;
          }
          @media(max-width:991px){
            border-radius:4px;
            padding:15px;
          }  
      }
      .notification-data-wrapper{
        padding:12px;
        display:flex;
        align-items:flex-start;
        justify-content:space-between;
        .unitifi-notification-content{
          font-size:16px;
          color:#fff;
          width:100%;
          margin-right:20px;
          a{
            color:#fff;
            &:hover{
              text-decoration:underline;
            }
          }
        }
      }
    }
        .unirifi-costomer-layout-wrapper{
              .subscription-layout{
                width: calc(100% - 45px);
                margin: 30px auto 0;
                height: 100%;
              }
              .ant-btn-primary{
                padding: 5px 15px;
                height: auto;
                font-size: 14px;
                font-weight: 600;
                text-decoration: none;
              }

          .filteText{
            a{
              font-size:14px;
              font-weight:400;
              line-height:15px;
              color:#EA3E3E;
              text-decoration:none;
              display: flex;
              align-items: center;
            }
              img{
                width:18px;
                height:18px;
              }
          }


          .backLink{
              cursor:pointer;
              padding:0 24px;
              a{
                text-decoration:none;
                span{
                  margin-left:15px;
                  color:#006FBA;
                  font-size:17px;
                  font-weight:400;
                  line-height:21px;
                }
              }
            }

          .unitifi-subheader-bar-wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 24px;
            > div {
              width: calc(100% - 150px);
              .title-bar-wrapper{
                padding: 0;
                background: transparent;
                border: none;
              }
            }
            .button-bar-wrapper{
              flex: 1;
              padding: 0;
            }
            .pageTitle{
                font-size:20px;
                font-weight:600;
                color:#181818;
                margin-bottom:0;
                @media only screen and (max-width: 767px) {
                  margin-bottom:10px;
                  padding:0;
                }
              }
              .qr-header-wrapper{
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                flex-wrap: wrap;
                h4{
                  color: #181818;
                  font-size: 20px;
                  font-weight: 600;
                  margin: 0;
                  display: flex;
                  align-items: center;
                  .ant-btn-icon-only{
                    width: 20px;
                    height: 20px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0;
                    background: transparent;
                    border: none;
                    margin-left: 8px;
                    i{
                      color: #026fba;
                    }
                  }
                  &.edit-iconbox{
                    align-items: flex-start;
                    .ant-btn-icon-only{
                      margin-top: 2px;
                    }
                  }
                }
                .copylink-block-wrapper{
                  display: flex;
                  align-items: center;
                  flex-wrap: wrap;
                  .copylink-inputbox{
                    display: flex;
                    align-items: center;
                    margin-right: 12px;
                    input{
                      background: #FFFFFF;
                      padding: 8px;
                      height: auto;
                      border-radius: 4px;
                      width: 240px;
                      margin-right: 10px;
                      outline: none;
                      box-shadow: none;
                      font-size: 16px;
                      font-weight: 400;
                      line-height: 20px;
                      text-align: left;
                      color: #969696;
                    }
                    .ant-btn-icon-only{
                      width: 38px;
                      height: 38px;
                      border: none;
                      background: #026fba;
                      i{
                        color: white;
                      }
                    }
                  }
                  .viewqr-btn{
                    min-height: 38px;
                    padding: 8px;
                    width: 100px;
                    background: #056fba;
                    border-color: #056fba;
                    font-size: 14px;
                    color: white;
                    font-weight: 600;
                  }
                }
              }
          }

          .unitifi-header{
            width: 100%;
            text-align: center;
            .ant-typography{
              color: #003F83;
              font-weight: 600;
              font-family:"Open Sans";
              font-size: 35px;
              line-height: normal;
              margin: 0;
            }
            p{
              font-weight: 400;
              font-size: 18px;
              line-height: 23px;
              letter-spacing: -0.02em;
              color: #6C6C6C;
            }
          }

          .header-client-menu-wrapper{
            background:#fff;
            border-radius:4px;
            padding:10px 16px;
            height:auto;
            margin:0 24px;
            display:flex;
            align-items:center;
            justify-content:space-between;
            @media only screen and (max-width: 1410px) {
              flex-direction:column;
              align-items:flex-start;
            }
            @media only screen and (max-width: 767px) {
              margin:0;
            }
            .header-client-left-content-data{
              max-width:500px;
              width:100%;
              display:flex;
              align-items:center;
              //justify-content:space-between;
              @media only screen and (max-width: 1299px) {
                max-width:500px;
                margin-bottom:10px;
              }
              @media only screen and (max-width: 540px) {
                flex-direction:column;
                align-items:flex-start;
              }
              .clientEmailData{
                margin-left:15px;
              }
              .client-title{
                font-size:25px;
                font-weight:600;
                color:#006fba;
                margin-bottom:0;
              }
              .client-detail{
                font-size:14px;
                font-weight:400;
                color:#969696;
              }
              .cmn-title-text{
                a{
                  font-size:14px;
                  font-weight:400;
                  color:#006fba;
                  display:flex;
                  align-items:center;
                  margin-top:10px;
                  margin-bottom:5px;
                  text-decoration:none;
                }
              }
            }
            .header-client-right-btn-data{
              @media only screen and (max-width: 1299px) {
                //margin-left:auto;
              }
              .ant-btn{
                &.btnCompare{
                  background:#fff;
                  border:1px solid #006fba;
                  border-radius:4px;
                  height:33px;
                  color:#006fba;
                  font-size:16px;
                  font-weight:600;
                }
                .anticon{
                  &.anticon-down{
                    svg{
                      margin-bottom:4px;
                    }
                  }
                }
              }
            }
          }

          .unitify-table-advisors-wrapper{
            .ant-table-wrapper{
              table{
                tbody{
                  tr{
                    td{
                      &:first-child{
                        a{
                          font-weight:600;
                          color:#006FBA;
                          text-decoration:none;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .unitifi-faq-content-wrapper{
            border-bottom: 1px solid #AFAFAF;
            padding:20px 0;
            .faq-heading{
              font-size:24px;
              font-weight:600;
              line-height:30px;
              color:#000;
              margin-bottom:12px;
              &.sub-heading{
                font-size:18px;
              }
            }
            .faq-info{
              font-size:20px;
              font-weight:400;
              line-height:30px;
              color:#000;
              &.faq-text{
                font-size:17px;
              }
            }
          }

          .public-form{
            &.faq-form{
              h3{
                 padding-top:30px;
                 font-size:20px;
                 font-weight:600;
                 line-height:30px;
                 color:#000; 
              }
              p{
                font-size:17px;
                font-weight:400;
                line-height:30px;
                color:#000;
              }
            }
          }

          .unitify-vehicle-tab-wrapper
            {
                background: ${palette("base", 0)};
                border: 1px solid rgba(238,242,244,1);
                border-radius: 4px;
                margin: 24px 24px;
                padding: 10px;
                max-width: 558px;
                display:flex;
                align-items:center;
                justify-content:space-around;
                .unitify-vehicle-link-data{
                  a{
                    font-size:14px;
                    font-weight:600;
                    line-height:17px;
                    color: #181818;
                    text-decoration: none;
                    &.active{
                      background: #006FBA;
                      border-radius: 70px;
                      width: auto;
                      padding:7px 14px;
                      color: #fff;
                      display: block;
                    }
                  }
                }
             }

            .unitify-content-box-wrapper{
                border-radius: 4px;
                margin: 24px 24px 0;
                .unitify-vehicle-main-wrapper{
                  display:flex;
                  overflow-y:hidden;
                  height:auto;
                  .unitify-vehicle-data-wrapper{
                    background: ${palette("base", 0)};
                    border: 1px solid rgba(238,242,244,1);
                    border-radius: 4px;
                    padding: 20px;
                    @media only screen and (max-width: 767px) {
                        max-width:767px;
                        width:100%;
                        flex:0 0 767px;
                    }
                    h4{
                      font-size: 22px;
                      font-weight:600;
                      line-height:27px;
                      color: #181818;
                      margin-left:12px;
                      margin-bottom:0;
                    }
                    .ant-table-wrapper{
                      table{
                        width:100% !important;
                        thead{
                          th{
                             background:none;
                             border-bottom: 1px solid #E8E8E8;
                             &:nth-child(3){
                              width:30%;
                             }
                             &:last-child{
                              text-align:right;
                             }
                            .ant-table-header-column{
                              span{
                                font-size:14px;
                                font-weight:600;
                                line-height:17px;
                                color:#181818;
                                text-transform: uppercase;
                              }
                            }
                          }
                        }
                        tbody{
                          tr{
                            &:hover{
                                background: #006FBA;
                                color:#fff;
                                transition: all 0.3s ease;
                                a{
                                  color:#fff;
                                }
                              }
                            td{
                              background: none;
                              a{
                                line-height:17px;
                                color:#4E4E4E;
                                text-decoration:none;
                                }
                                &.action-column {
                                  i {
                                        cursor: pointer;
                                        width: 18px;
                                        height: 14px;
                                        display:block;
                                    }
                                }
                              &:last-child{
                                text-align:right;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                .unitify-vehicle-chart-wrapper{
                  background: #fff;
                  border-radius:4px;
                  margin-bottom:10px;
                  @media only screen and (max-width: 991px) {
                    margin-top:10px;
                  }
                  svg{
                    g{
                      fill:red;
                    }
                  }
                  h6{
                    font-size:14px;
                    font-weight:600;
                    line-height:17px;
                    text-transform:uppercase;
                    padding:10px 15px;
                    margin-bottom:0;
                  }
                  .unitify-cmn-circle{
                    display:block;
                    width:16px;
                    height:16px;
                    border-radius:30px;
                    background-color: #004F86;
                    &.status-bg{
                      background-color:#C2CFE3;
                    }
                    &.status-circle{
                        background:#006FBA;
                    }
                  }
                }
                .unitify-vehicle-status-wrapper{
                  background: #fff;
                  border-radius:4px;
                  overflow: hidden;
                  /* max-width:357px;
                  width:100%; */
                  .title-bar-wrapper{
                    padding:10px 25px;
                    border-bottom:none;
                    .title-wrapper{
                      .title-text{
                        font-size:14px;
                        font-weight:600;
                        line-height:17px;
                        color:#181818;
                        text-transform:uppercase;
                      }
                    }
                  }
                  .unitify-vehicle-content-wrapper{
                    width:100%;
                    padding:10px 25px;
                    @media only screen and (max-width: 1199px) {
                      padding:10px 10px;
                    }
                    > div{
                      &:last-child{
                        margin-bottom:10px !important;
                      }
                      .unitify-cmn-title{
                        font-size:14px;
                        font-weight:400;
                        line-height:17px;
                        color: #181818;
                        width: 85px;
                        padding-right: 10px;
                        flex:0 0 85px;
                        @media only screen and (max-width: 1199px) {
                          width: 60px;
                          padding-right: 5px;
                          flex:0 0 60px;
                        }
                      }
                      .ant-progress{
                        > div {
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                          .ant-progress-outer{
                            flex: auto;
                            margin:0;
                            padding: 0;
                            .ant-progress-inner{
                              .ant-progress-bg{
                                height:16px !important;
                                background-color:#004F86;
                              }
                            }
                          }
                          .ant-progress-text{
                            width:60px;
                            flex:0 0 60px;
                            padding-left:10px;
                            margin:0;
                            line-height:17px;
                            color:#181818;
                          }
                        }
                      }
                    }
                  }
                }
            } 

          .addnew-link-btn-wrapper{
              display: flex;
              //align-items: center;
              line-height: normal;
          }

          .unitifi-learning-center-heading{
            padding:10px 24px;
            @media only screen and (max-width: 991px) {
              //padding:10px 30px;
            }
            @media only screen and (max-width: 575px) {
              padding:10px 20px;
            }
            .title-bar-wrapper{
              background:transparent;
              padding:0;
              margin-bottom:10px;
            }
            .unitifi-headLinks{
              //color:#969696;
              color:rgba(24,24,24,0.7);
              font-size:20px;
              font-weight:600;
              text-decoration:none;
              &.active{
                color: #006FBA;
                text-decoration:underline;
              }
              @media only screen and (max-width: 991px) {
                  font-size:18px;
              }
              @media only screen and (max-width: 575px) {
                  font-size:15px;
              }
              @media only screen and (max-width: 480px) {
                  font-size:14px;
              }
            }
            .unitifi-videoBlock-wrapper{
              background:#fff;
              border-radius:4px;
              padding:30px 50px;
              margin:30px 0 50px;
              @media only screen and (max-width: 1199px) {
                  padding:25px 30px;
              }
              .videoBlock-title{
                font-size:20px;
                font-weight:600;
                color:#000;
                margin-bottom:0;
                @media only screen and (max-width: 1199px) {
                  font-size:22px;
                }
                @media only screen and (max-width: 767px) {
                  font-size:18px;
                }
              }

              .personalityBlock-wrapper{
                margin-bottom:50px;
                 .personality-cmn-block{
                    background:#F2F2F2;
                    border-radius:10px;
                    height:152px;
                    display:flex;
                    align-items:center;
                    justify-content:center;
                    flex-direction:column;
                    margin-bottom:20px;
                    cursor:pointer;
                    &:hover{
                    opacity:0.7;
                   }
                    &.protector{
                      border:4px solid #D230AF;
                    }
                    &.observer{
                      border:4px solid #30ABD2;
                    }
                    &.liberator{
                      border:4px solid #9B4BEB;
                    }
                    &.energizer{
                      border:4px solid #D2A530;
                    }
                    h4{
                      font-size:18px;
                      font-weight:600;
                      line-height:30px;
                      color:#000000;
                      margin-bottom:0;
                      @media only screen and (max-width: 991px) {
                        line-height:20px;
                      }
                    }
                 }
              }

              .behaviourBlock-wrapper{
                .behaviour-cmn-block{
                   padding:15px;
                   background:#589BCB;
                   display:flex;
                   align-items:center;
                   justify-content:center;
                   width:100%;
                   height:180px;
                   border-radius:3px;
                   margin-bottom:24px;
                   cursor:pointer;
                   &:hover{
                    opacity:0.7;
                   }
                   &.blue-box{
                    background:#054471;
                   }
                   .behaviour-icon-detail{
                    display:flex;
                    flex-direction:column
                    align-items:center;
                    i{
                      width:75px;
                      height:75px;
                      overflow:hidden;
                      margin-bottom:10px;
                      img{
                        width:100%;
                        height:100%;
                        object-fit:contain;
                        object-position:center;
                      }
                    }
                    p{
                      text-align:center;
                      font-size:15px;
                      font-weight:600;
                      color:#fff;
                    }
                   }
                }
              }

              .searchbox-form-wrapper{
                .search-input-wrapper{
                  display: flex;
                  align-items: center;
                  margin-right: 15px;
                  position: relative;
                  cursor:pointer;
                  @media only screen and (max-width: 575px) {
                      margin-top:12px;
                      margin-left:-14px;
                    }
            i{
              position: absolute;
              right: 15px;
              width: 18px;
              height: 18px;
              display: flex;
            }
            input{
              background:#f4f4f4;
              width: 335px;
              max-width: 100%;
              outline: none;
              box-shadow: none;
              padding: 8px;
              padding-left: 15px;
              border: none;
              color: #7E7E7E;
              font-size: 14px;
              font-weight: 400;
              @media only screen and (max-width: 991px) {
                      width:250px;
                    }
                    @media only screen and (max-width: 767px) {
                      width:220px;
                    }
                    @media only screen and (max-width: 480px) {
                      width:200px;
                    }
            }
            &.input-group{
              .input-group-append{
                .input-group-text{
                  width:48px;
                  height:37px;
                  border-radius:0 4px 4px 0;
                  border:0;
                  background:#006FBA;
                }
              }
            }
          }
              }
              .unitifi-categoryblock-wrapper{
                  padding:21px 25px;
                  .categoryBlock-title{
                    font-size:25px;
                    font-weight:600;
                    color:#000;
                    margin-bottom:0;
                    @media only screen and (max-width: 1199px) {
                       font-size:22px;
                     }
                     @media only screen and (max-width: 575px) {
                       font-size:20px;
                     }
                     @media only screen and (max-width: 480px) {
                       font-size:18px;
                     }
                  }
                  .videoBlock-wrapper{
                    margin:30px 0;
                    .videoBlock{
                      background:#D9D9D9;
                      height:350px;
                      margin-bottom:20px;
                      @media only screen and (max-width: 991px) {
                       height:250px;
                      }
                      @media only screen and (max-width: 767px) {
                       height:200px;
                      }
                      img{
                        width:100%;
                        height:100%;
                        object-fit:cover;
                        object-position:center;
                      }
                      h2{
                        font-size:60px;
                        font-weight:600;
                        color:#000;
                        margin-bottom:0;
                      }
                    }
                    .videoDetail-block{
                      h4{
                        a{
                          font-size:24px;
                          font-weight:600;
                          line-height:30px;
                          color:#000;
                          text-decoration:none;
                          @media only screen and (max-width: 1499px) {
                            font-size:20px;
                          }
                          @media only screen and (max-width: 575px) {
                            font-size:18px;
                          }
                          @media only screen and (max-width: 480px) {
                            font-size:16px;
                          }
                        }
                      }
                      h6{
                        font-size:16px;
                        font-weight:400;
                        line-height:30px;
                        color:#000;
                        word-break:break-word;
                        //max-width:440px;
                        width:100%;
                        margin-bottom:0;
                        &.title-desc{
                          max-width:350px;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          @media only screen and (max-width: 1600px) {
                            max-width:270px;
                          }
                        }
                        @media only screen and (max-width: 1399px) {
                            max-width:100%;
                        }
                        @media only screen and (max-width: 991px) {
                            word-break:break-word;
                        }
                        @media only screen and (max-width: 480px) {
                            font-size:14px;
                        }
                      }
                      .showMore{
                        font-size:16px;
                        font-weight:600;
                        text-decoration:underline;
                        color:#969696;
                        @media only screen and (max-width: 1499px) {
                            font-size:14px;
                        }
                      }
                  }
                  .slick-slider{
                    .slick-arrow{
                      &.slick-prev{
                        width:40px;
                        height:40px;
                        background:rgba(168,168,169,0.1);
                        border-radius:50%;
                        @media only screen and (max-width: 991px){
                         top:40%;
                        }
                        &::before{
                          content:'';
                          background: url("/img/slider-next.svg") no-repeat;
                          width:40px;
                          height:40px;
                          display:block;
                          position:relative;
                          top:-8px;
                          left:-8px;
                          transform:rotate(180deg);
                        }
                      }
                      &.slick-next{
                        width:40px;
                        height:40px;
                        background:rgba(168,168,169,0.1);
                        border-radius:50%;
                        @media only screen and (max-width: 991px){
                         top:40%;
                        }
                        &::before{
                          content:'';
                          background: url("/img/slider-next.svg") no-repeat;
                          width:40px;
                          height:40px;
                          display:block;
                          position:relative;
                          top:7px;
                          left:8px;
                        }
                      }
                    }
                  }
                }
                 
              }
            }
          }

          .unitifi-customer-content-wrapper{
             display:flex;
             align-items:center;
             justify-content:space-between;
             margin:24px 24px;
             @media only screen and (max-width: 991px){
              flex-direction:column;
             }
            .unitifi-customer-info-box-wrapper{
              background: #fff;
              max-width:900px;
              width:100%;
              border-radius:4px;
              padding:10px 0;
              position:relative;
              /* @media only screen and (max-width: 767px) {
                max-width:300px;
              } */
              /* .unitifi-customer-info-col{ */
                /* border-right:1px solid #ececec;
                @media only screen and (max-width: 767px) {
                  border-bottom:1px solid #ececec;
                }  */
              
                /* &:last-child{
                  border-right:0;
                  @media only screen and (max-width: 767px) {
                    border-bottom:0;
                }  
                } */

                .ant-row {
                    .ant-col{
                        &.ant-col-md-6{
                          &:first-child {
                              .unitifi-customer-info-block {
                                  &::before {
                                      content: unset;
                                  }
                              }
                          }
                       }
                    }
                }

                .unitifi-customer-info-block{
                  padding:20px 0 15px 0;
                  &::before{
                        position: absolute;
                        content: "";
                        top: 0;
                        bottom: 0;
                        background: #ececec;
                        width: 1px;
                        height: 100%;
                        @media(max-width:767px){
                          content:unset;
                        }
                  }
                }
              /* } */
              h6{
                font-size:16px;
                font-weight:600;
                color:${palette("base", 8)};
                margin-bottom:14px;
                text-align:center;
                i{
                  cursor:pointer;
                }
              }
              p{
                font-size:22px;
                color:${palette("primary", 12)};
                text-align:center;
                word-break:break-word;
                width:160px;
                margin:auto;
                &.text-sm{
                  font-size:14px;
                }
              }
            }
            .ant-btn{
              &.btnManageUser{
                border-color: #006FBA;
                color: #006FBA;
                font-size:14px;
                font-weight:600;
                border-radius:4px;
              }
              &.btnDelete{
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 8px;
                height: 32px;
                width: 159px;
                &.btnBorder{
                  border:1px solid #3ABB47;
                }
              }
            }
          }

          .display-bar{
            font-size: 16px;
            padding-bottom: 30px;
            font-weight: 600;
          }
          .ant-page-header{
            .ant-page-header-heading{
              align-items: center;
              display: flex;
              justify-content: space-between;
              @media only screen and (max-width: 768px) {
                flex-direction: column;
                align-items: self-start;
              }
              .ant-page-header-heading-sub-title{
                margin: 0px;
                font-size: 18px;
                width: calc(100% - 370px);
                @media only screen and (max-width: 768px) {
                  width: 100%;
                  margin-bottom: 15px;
                }
              }
              .ant-page-header-heading-extra{
                display:flex;
                @media only screen and (max-width: 575px) {
                  flex-wrap:wrap;
                }
              }
            }
            &.unitifi-client-subheader-bar{
              margin: 20px 24px;
              .ant-page-header-heading{
                .ant-page-header-heading-sub-title{
                  display:flex;
                  align-items:center;
                  .title-bar-wrapper{
                    border-bottom:none;
                    .title-wrapper{
                      .title-text{
                        font-size:23px;
                        font-weight:600;
                        color:#006fba;
                      }
                      .sub-title-text{
                        font-size:14px;
                        color:#7e7e7e;
                      }
                    }
                  }
                }
              }
            }
          }
          .unitifi-consumer-header-bar{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 18px;
            &.compareviewBox{
              margin-bottom:0;
              padding:10px 24px 0;
            }
            @media only screen and (max-width:767px) {
              padding:0;
            }
            @media only screen and (max-width: 575px) {
               flex-direction:column;   
            }

            .client-header-detail-box{
              display:flex;
              align-items:center;
              justify-content:space-between;
              background:#fff;
              height:auto;
              border-radius:4px;
              padding:10px;
              margin-right: 15px;
              @media only screen and (max-width: 575px) {
                width:100%;
                margin-bottom:10px;
              }
              @media only screen and (max-width: 480px) {
                flex-direction:column;
              }
              .client-header-left-content{
                display:flex;
                align-items:center;
                flex-wrap:wrap;
                .client-detail-info{
                  display:flex;
                  align-items:center;
                  @media only screen and (max-width: 1040px) {
                    align-items:flex-start;
                    flex-direction:column;
                  }
                  @media only screen and (max-width: 767px) {
                    margin-bottom:10px;
                  }
                  &:last-child{
                    @media only screen and (max-width: 480px) {
                      margin-bottom:0;
                    } 
                  }
                  .clientTitle{
                    font-size:23px;
                    font-weight:600;
                    color:#006fba;
                    margin-bottom:0;
                    margin-right:15px;
                    @media only screen and (max-width: 767px) {
                      font-size:18px;
                    }
                    @media only screen and (max-width: 480px) {
                      font-size:16px;
                    }
                    @media only screen and (max-width: 1210px) {
                      font-size:14px;
                    }
                    &.secondClient{
                      color:#cb9120;
                    }
                  }
                  .cmn-title-text{
                    margin-bottom:0;
                    margin-right:15px;
                    &.phoneData{
                      margin-right:0;
                    }
                    a{
                      font-size:14px;
                      font-weight:400;
                      color:#006fba;
                      display:flex;
                      align-items:center;
                      margin-top:5px;
                      margin-bottom:5px;
                      text-decoration:none;
                    }
                  }
                }
                .seperator{
                  border: 1px solid rgba(238, 242, 244, 1);
                  width: 1px;
                  height: 53px;
                  margin:0 10px;
                  @media only screen and (max-width: 1040px) {
                    border:none;
                  }
                }
              }
            }
            .downloadBtn-wrapper{
              @media only screen and (max-width: 575px) {
                margin-left:auto;
              } 
            }

           
            .unitifi-header{
              width: calc(100% - 150px);
              text-align: center;
              margin-left: 75px;
              &.customHeader{
                width:unset;
                margin-left:unset;
              }
              .ant-typography{
                color: #003f83;
                font-weight: 600;
                font-size: 35px;
                line-height: normal;
                margin: 0;
              }
              p{
                font-weight: 400;
                font-size: 18px;
                line-height: 23px;
                
                letter-spacing: -0.02em;
                color: #6C6C6C;
              }
            }
            .button-bar-wrapper{
              padding: 0;
            }
          }
          .switchbox-header-bar{
            display: flex;
            justify-content: space-between;
            width: 100%;
            .form-switch{
              min-height: auto;
              margin-bottom: 0;
              .form-check-input{
                margin-top: 6px;
              }
              label{
                font-size: 14px;
                font-weight: 500;
              }
            }
          }
          .ant-table-bordered .ant-table-thead > tr:not(:last-child) > th {
            //border-bottom: none;
          }
          .ant-table-bordered .ant-table-thead > tr > th {
            &.colOverview{
              border-bottom: none;
            }
          }
          .table-bordered .ant-table-tbody > tr > td {
            border-right: none;
          }
          .ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
            border-right: 0;
          }
          .ant-table-fixed{
            border: none;
            thead{
              tr{
                &:last-child{
                  th{
                    //border-bottom: none;
                  } 
                }
                th{
                  border-right: none;
                  &.name-column{
                    width:350px;
                  }
                  &.location-column{
                    width:400px;
                  }
                  &.status-column{
                    width:150px;
                  }
                  &.email-column{
                    width:270px;
                  }
                  &.access-column{
                    width:150px;
                  }
                  &.group-column{
                    width:200px;
                  }
                  &.action-column{
                    text-align:right;
                    width:70px;
                    min-width:70px;
                    @media only screen and (max-width: 1439px) {
                      width:unset;
                      white-space:nowrap;
                    }
                    &.viewAction{
                      width:5px;
                    }
                    &.viewColumn{
                      width:100px;
                    }
                  }
                }
              }
            }
            tbody{
              tr{
                td{
                  .practicelink-text{
                    font-size: 14px;
                    font-weight: 600;
                    color: rgba(0,111,186,1);
                    text-decoration: none;
                  }
                }
              }
            }
          }
          .unitifi-select-container{
            .unitifi-react-select__control{
              padding: 10px;
              background: #F4F4F4;
              min-height: 36px;
              height: 36px;
              outline: none;
              box-shadow: none;
              border-color: #F4F4F4;
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              &:hover{
                border-color: #F4F4F4;
              }
              &:focus{
                border-color: #006FBA;
              }
              .unitifi-react-select__value-container{
                font-weight: 400;
                font-size: 14px;
                line-height: normal;
                color: #4E4E4E;
                padding: 0;
                margin-top: -4px;
                .unitifi-react-select__placeholder{
                  font-weight: 400;
                  font-size: 14px;
                  line-height: normal;
                  color: #4E4E4E;
                  margin: 0;
                  padding: 0;
                }
                .unitifi-react-select__input-container{
                  font-weight: 400;
                  font-size: 14px;
                  line-height: normal;
                  color: #4E4E4E;
                  margin: 0;
                  padding: 0;
                }
              }
              .unitifi-react-select__indicators{
                margin-top: -4px;
                .unitifi-react-select__indicator-separator{
                  display: none;
                }
                .unitifi-react-select__indicator{
                  padding: 0;
                }
              }
            }
            .unitifi-react-select__menu{
              .unitifi-react-select__menu-list{
                padding: 0;
                .unitifi-react-select__option{
                  font-size: 14px;
                  font-weight: 600;
                  &.unitifi-react-select__option--is-selected{
                    background: white;
                    color: black;
                  }
                  &.unitifi-react-select__option--is-focused{
                    background: #026fba;
                    color: white;
                  }
                }
              }
            }
          }
        }
      }
    }
    .ant-layout-footer {
      background: /*rgba(249,250,252,1)*/ rgba(240,242,245,1);
      color: rgba(0,0,0,0.45);
      /* padding: 24px; */
      padding: 0 24px 24px;
      text-align: center;
    }
    /* .ant-layout-header {
      align-items: center;
      background: ${palette("base", 0)};
      border-bottom: 1px solid rgb(232, 232, 232);
      display: flex;
      height: 64px;
      justify-content: space-between;
      line-height: 32px;
      padding: 0 40px;
    } */
    /* .ant-menu {
      border-bottom: none;
      line-height: 62px;

      .ant-menu-item {

        >a {
          &:hover {
            color: ${palette("primary", 0)};
          }
        }
      }

      .ant-menu-item-selected {
        >a {
          color: ${palette("primary", 0)};
          &:hover {
            color: ${palette("primary", 0)};
          }
        }
      }
    } */

    /* .ant-menu-horizontal {
      >.ant-menu-item {
        border-bottom: none;
        font-weight: 600;
        &:hover {
          border-bottom: none;
        }
      }
      >.ant-menu-item-selected {
        border-bottom: none;
      }
    } */

    .loading-wrapper {
      align-items: center;
      display: flex;
      justify-content: center;
      /* min-height: calc(100vh - 98px - 58px); */
      min-height: calc(100vh - 98px - 140px);
      .ant-spin-dot {
        color: rgba(0,111,186,1);
      }
    }
  }

  .menu-trigger-wrapper {
    align-items: center;
    border-bottom-right-radius: 50%;
    border-top-right-radius: 50%;
    box-shadow: 0px 0px 8px rgba(0,0,0,0.15);
    color: rgba(101,122,133,1);
    display: flex;
    height: 40px;
    justify-content: center;
    position: fixed;
    transition: all .2s;
    top: 272px;
    z-index: 1;
    @media only screen and (max-width: 575px) {
      top:130px;
    }
    &.collapsed {
      /* background: rgba(188,198,218,1); */
      background: rgba(255,255,255,1);
      left: 0;
      width: 40px;
    }
    i {
      &.trigger {
        cursor: pointer;
        font-size: 18px;
        padding: 11px;
        transition: all .2s;
        &.collapsed {
          /* color: rgba(255,255,255,1); */
          margin-left: -4px;
        }
      }
    }
  }
  .slick-slide { -webkit-transform: translate3d(0,0,0) !important; }
  .slick-track {margin-left:0;}
`;

export default ContentWrapper;
