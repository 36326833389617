import React, { useContext, useEffect, useState } from "react";
import { useQuery, useApolloClient, useLazyQuery } from "react-apollo";
import { Badge, Button, Form, Icon, Input, Skeleton, Spin, Table, Modal, notification } from "antd";
import { practiceConfig } from "../../../config";
import GenerateBadge from "../../../helpers/generateBadge";
import LayoutContent from "../../../layouts/app/layoutContent";
import LayoutContentWrapper from "../../../layouts/app/layoutWrapper";
import CustomButton from "../../../components/commons/CustomButton";
import _ from "lodash";
import gql from "graphql-tag";
import { AccountContext } from "../../../context/AccountContext";
import TitleBar from "../../../components/titlebar/titlebar";

const { confirm } = Modal;

function PracticesList(props) {
  const { location, currentPortalId } = props;
  const searchText = new URLSearchParams(location.search).get("search");
  const [practices, setPractices] = useState([]);
  const [tableCount, setTableCount] = useState(0);
  const client = useApolloClient();
  const [state, setState] = useState({ limit: practiceConfig.rowsPerPage, loading: false, order: "ASC", orderColumn: "", page: 1, search: "", totalCount: null, sortDirection: "" });
  const accountContext = useContext(AccountContext);
  const { ownerPractice } = accountContext;

  const permissions = _.get(accountContext.account, "permissions", []);
  const account = _.get(accountContext, "account", null);

  useEffect(() => {
    practiceHandler({
      variables: {
        page: state.page,
        offset: state.offset,
        limit: state.limit,
        order: state.order,
        orderColumn: state.orderColumn,
        search: searchText,
        portalId: props.currentPortalId,
        practiceId: props.me.practiceid,
        accountType: props.me.accountType,
        roleCode: props.me.rolecode,
      },
    });
  }, [searchText, state.page]);

  const [practiceHandler, { loading, data }] = useLazyQuery(
    gql`
      query($limit: Int, $offset: Int, $order: String, $orderColumn: String, $search: String, $portalId: Int, $practiceId: Int, $roleCode: String, $accountType: Int) {
        getAdminPractices(limit: $limit, offset: $offset, order: $order, orderColumn: $orderColumn, search: $search, portalId: $portalId, practiceId: $practiceId, roleCode: $roleCode, accountType: $accountType) {
          practices {
            id
            name
            practiceId
            portalId
            company
            cslug
            primaryContactAddress
            primaryContactCity
            status
            primaryContactEmail
            primaryContactFirstName
            primaryContactLastName
            ownername
          }
          pagination {
            offset
            totalCount
          }
        }
      }
    `,
    {
      variables: {
        page: state.page,
        offset: state.offset,
        limit: state.limit,
        order: state.order,
        orderColumn: state.orderColumn,
        search: searchText,
        portalId: props.currentPortalId,
        practiceId: props.me.practiceid,
        accountType: props.me.accountType,
        roleCode: props.me.rolecode,
      },
      fetchPolicy: "network-only",
    }
  );

  const deleteHandler = (id, name) => {
    if (!ownerPractice) {
      window.location.reload();
      return;
    }
    confirm({
      title: "Are you sure you want to delete this Practice?",
      content: `If you proceed, all clients and the advisor assigned to ${name} will be assigned to ${ownerPractice ? ownerPractice.ownerPracticeName : ""} (the default Practice) until being assigned to a new Practice.`,
      okText: "Yes",
      okType: "danger",
      cancelText: "Cancel",
      icon: "close-circle",

      onOk() {
        return new Promise(async (resolve, reject) => {
          let data = await deleteHandlerSubmit(id);
          resolve();
        }).catch(() => console.log("error"));
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const deleteHandlerSubmit = async (id) => {
    let result = await client.mutate({
      mutation: gql`
        mutation($id: Int!, $ownerPracticeId: Int, $ownerUserId: Int, $portalId: Int) {
          deletePractice(id: $id, ownerPracticeId: $ownerPracticeId, ownerUserId: $ownerUserId, portalId: $portalId) {
            ok
            errors {
              message
            }
          }
        }
      `,
      variables: { id: id, ownerPracticeId: ownerPractice.ownerPracticeId, portalId: currentPortalId, ownerUserId: ownerPractice.ownerUserId },
    });

    if (result && result.data.deletePractice.ok) {
      notification.success({
        message: "Practice Deleted Successfully",
      });
      reCall();
    }
    return true;
  };

  function reCall() {
    practiceHandler({
      variables: {
        page: state.page,
        offset: state.offset,
        limit: state.limit,
        order: state.order,
        orderColumn: state.orderColumn,
        search: searchText,
      },
    });
  }

  function handleTableChange(pagination, filters, sorter) {
    const sorterOrder = sorter.order === "descend" ? `DESC` : `ASC`;

    let x = (pagination.current - 1) * state.limit;
    setState({
      ...state,
      offset: x,
      limit: pagination.pageSize,
      page: pagination.current == state.page ? 1 : pagination.current,
      order: sorterOrder,
      orderColumn: sorter.field,
      [sorter.columnKey]: sorter.order,
    });

    practiceHandler({
      variables: {
        page: pagination.current == state.page ? 1 : pagination.current,
        offset: x,
        limit: pagination.pageSize,
        order: sorterOrder,
        orderColumn: sorter.field,
        search: searchText,
        portalId: props.currentPortalId,
        practiceId: props.me.practiceid,
        accountType: props.me.accountType,
        roleCode: props.me.rolecode,
      },
    });
  }

  const columns = [
    {
      dataIndex: "name",
      sorter: true,
      title: "Practice Name",
      width: 100,
      render: (text, row) => {
        if ([1, 4, 5].includes(props.gblUserRole))
          return (
            <Button
              type={"link"}
              disabled={row.status === "Inactive"}
              onClick={() => {
                props.history.push(`/${row.practiceId}`);
                localStorage.setItem("current_practice", row.practiceId);
                localStorage.setItem("current_practice_id", row.id);
              }}>
              {text}
            </Button>
          );
        else if ([2, 6].includes(props.gblUserRole)) return text;
        else return <>{text}</>;
      },
    },
    // {
    //   dataIndex: "primaryContactCity",
    //   className: "location-column",
    //   title: "Location",
    //   width: 100,
    //   sorter: true,
    // },
    {
      dataIndex: "status",
      render: (text) => <Badge status={GenerateBadge(text)} text={text} />,
      className: "status-column",
      title: "Status",
      width: 70,
      sorter: true,
    },
    {
      dataIndex: "ownername",
      className: "email-column",
      title: "Admin Name",
      width: 100,
      sorter: true,
      render: (text, row) => {
        if ([1, 4, 5].includes(props.gblUserRole))
          return (
            <Button type={"link"} onClick={() => props.history.push(`/companies/${row.cslug}`)}>
              {row.ownername}
            </Button>
          );
        else if ([2, 6].includes(props.gblUserRole)) return row.ownername;
        else return <>{text}</>;
      },
    },
    {
      dataIndex: "primaryContactEmail",
      className: "email-column",
      title: "Admin Email",
      width: 100,
      sorter: true,
      render: (text, row) => {
        if ([1, 4, 5].includes(props.gblUserRole))
          return (
            <Button type={"link"} onClick={() => props.history.push(`/companies/${row.cslug}`)}>
              {row.primaryContactEmail}
            </Button>
          );
        else if ([2, 6].includes(props.gblUserRole)) return row.primaryContactEmail;
        else return <>{text}</>;
      },
    },
    {
      className: "action-column",
      title: "View",
      //width:40,
      render: (practice) => {
        // if ([1, 4, 5].includes(props.gblUserRole)) return <Button type={"link"} icon={"eye"} onClick={() => props.history.push(`/practices/view/${practice.portalId}/${practice.id}`)} />;
        if (permissions.includes("viewpractice")) return <Button type={"link"} icon={"eye"} onClick={() => props.history.push(`/practices/view/${practice.portalId}/${practice.id}`)} />;
        // else if ([2, 6].includes(props.gblUserRole)) return <Button type={"link"} icon={"eye"} onClick={() => props.history.push(`/${props.currentPractice}/practices/view/${practice.portalId}/${practice.id}`)} />;
        // else return <></>;
      },
    },

    {
      className: "action-column",
      title: "Edit",
      //width:40,
      render: (practice) => {
        if (permissions.includes("editpractice")) return <Button type={"link"} icon={"edit"} onClick={() => props.history.push(`/practices/edit/${practice.portalId}/${practice.id}`)} />;
        // if ([1, 4, 5].includes(props.gblUserRole)) return <Button type={"link"} icon={"edit"} onClick={() => props.history.push(`/practices/edit/${practice.portalId}/${practice.id}`)} />;
        // else if ([2, 6].includes(props.gblUserRole)) return <Button type={"link"} icon={"edit"} onClick={() => props.history.push(`/${props.currentPractice}/practices/edit/${practice.portalId}/${practice.id}`)} />;
        // else return <></>;
      },
    },
    {
      className: "action-column",
      title: "Delete",
      //width:40,
      render: (practice) => {
        if (permissions.includes("deletepractice") && practice.id !== props.me.practiceid) return <Button type={"link"} icon={"delete"} onClick={() => deleteHandler(practice.id, practice.name)} />;
      },
    },
  ];

  // let practices = _.get(data, "getAdminPractices.practices", []);
  let tempTableCount = _.get(data, "getAdminPractices.pagination.totalCount", 0);

  useEffect(() => {
    if (!data) return;
    setTableCount(tempTableCount > tableCount || tableCount == undefined ? tempTableCount : tableCount);

    setPractices(data.getAdminPractices.practices);
  }, [data]);

  let tablePagination = {
    current: state.page,
    defaultPageSize: practiceConfig.rowsPerPage,
    pageSize: state.limit,
    pageSizeOptions: ["10", "20", "50", "100"],
    showQuickJumper: true,
    showSizeChanger: true,
    total: tableCount,
  };

  const countStart = (state.page - 1) * state.limit + 1;
  const countEnd = state.page * state.limit > tableCount ? tableCount : state.page * state.limit;
  const display = tableCount ? `Displaying ${countStart}-${countEnd} of ${tableCount}` : "No Records";

  return (
    <div className="unirifi-costomer-layout-wrapper">
      <div className="unitifi-subheader-bar-wrapper">
        <TitleBar title="Practices" subTitle={account && (account.accountType == 1 ? account.portalname : `${account.practicename} | ${account.portalname}`)} />

        {accountContext.account && accountContext.account.permissions.includes("createpractice") && (
          <CustomButton rights={["superAdmin", "createpractice"]} icon="plus" href={`/practices/add/${props.currentPortalId}`} text="Add New" type={"primary"} className="addnew-link-btn-wrapper">
            Add New
          </CustomButton>
        )}
      </div>
      <LayoutContentWrapper>
        <div className="display-bar">{display}</div>
        <LayoutContent className="practiceTblWrapper">
          <Table
            columns={columns}
            loading={
              loading && {
                indicator: <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />,
              }
            }
            onChange={handleTableChange}
            dataSource={practices}
            rowKey={(record) => record.id}
            scroll={{ x: columns.length * 150 }}
            pagination={tablePagination}
          />
        </LayoutContent>
      </LayoutContentWrapper>
    </div>
  );
}

export default Form.create()(PracticesList);
